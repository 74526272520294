import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import {
	referralsWalletHideLoading,
	referralsWalletsRequest,
	referralsWalletsSuccess,
	referralsWalletTransferRequest,
	referralsWalletTransferSuccess,
} from './reducer';
import { notificationContainer } from '../../../services/utils/notificationContainer';
import { api } from '../../../services';
import { IPostReferralsWalletTransfer, IReferralsWallet } from './types';
import { IReferralsWalletTransferResponse } from '../../../services/api/referrals/types';

function* referralsWalletsWorker() {
	try {
		const response: IReferralsWallet[] = yield call(api.referrals.getReferralsWallets);
		yield put(referralsWalletsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log(error?.response?.data.errors);
			// notificationContainer(error?.response?.data.errors[0], 'error');
		}
		yield put(referralsWalletHideLoading());
	}
}

function* referralsWalletTransferWorker({ payload }: PayloadAction<IPostReferralsWalletTransfer>) {
	const { apiParams, onFinally } = payload;
	let hasError = false;
	let status = true;
	let message = '';
	try {
		const response: IReferralsWalletTransferResponse = yield call(
			api.referrals.postReferralsWalletTransfer,
			apiParams,
		);
		status = response.status;
		if (response?.message) {
			message = response?.message;
		}
		yield put(referralsWalletTransferSuccess(response));
		yield put(referralsWalletsRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			hasError = true;
			console.log(error?.response?.data.errors);
			// notificationContainer(error?.response?.data.errors[0], 'error');
		}
		yield put(referralsWalletHideLoading());
	} finally {
		yield put(referralsWalletHideLoading());
		onFinally?.(hasError, status, message);
	}
}

export function* referralsSaga() {
	yield takeLatest(referralsWalletsRequest, referralsWalletsWorker);
	yield takeLatest(referralsWalletTransferRequest, referralsWalletTransferWorker);
}
