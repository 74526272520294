/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IGetOndatoLinkWorker,
	ISettingsStore,
	IVerificationFilesRequestPayloadData,
} from './types';

// ==========================================:
export const initialState: ISettingsStore = {
	verificationFilesLoader: false,
	uploadProgress: 0,
	verificationFilesUploaded: false,
	files: [],
	getOndatoLinkLoading: true,
};

// ==========================================:
const settings = createSlice({
	name: '@@settings',
	initialState,
	reducers: {
		verificationFilesRequest: (
			state,
			action: PayloadAction<IVerificationFilesRequestPayloadData>,
		) => {
			const stateData = state;
			stateData.verificationFilesLoader = true;
		},
		verificationFilesSuccess: (state) => {
			const stateData = state;
			stateData.verificationFilesLoader = false;
			stateData.verificationFilesUploaded = true;
		},
		disableTwoFaRequest: (state, action) => {},
		settingsInitState: () => initialState,
		updateUploadProgress: (state, action: PayloadAction<number>) => {
			const stateData = state;
			stateData.uploadProgress = action.payload;
		},
		verificationFilesSave: (state, action: PayloadAction<File[]>) => {
			const stateData = state;
			stateData.files = action.payload;
		},
		getOndatoLinkRequest: (state, action: PayloadAction<IGetOndatoLinkWorker>) => {
			const stateData = state;
			stateData.getOndatoLinkLoading = true;
		},
		getOndatoLinkSuccess: (state) => {
			const stateData = state;
			stateData.getOndatoLinkLoading = false;
		},
	},
});
export default settings.reducer;
export const {
	verificationFilesRequest,
	verificationFilesSuccess,
	settingsInitState,
	disableTwoFaRequest,
	updateUploadProgress,
	verificationFilesSave,
	getOndatoLinkRequest,
	getOndatoLinkSuccess,
} = settings.actions;
