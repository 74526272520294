import { IWalletsGetParams } from 'services/api/wallets/types';

export interface IWalletsStore {
	walletsList: IWalletItem[] | null;
	walletsBalance: IWalletsBalance | null;
	walletsLoader: boolean;
	layers: Record<string, unknown> | null;
}

// =============================================================:

export interface IGetWalletsRequestPayloadData {
	disableLoading?: boolean;
	params?: IWalletsGetParams;
}

export interface IWalletItem {
	asset_id: any;
	id: number;
	asset: IWalletAssetItem;
	balance: string;
	frozen_balance: string;
	tag: null | string;
	has_withdrawal_tag: boolean;
	btc_value: string;
	usd_value: string;
	balance_eur: number;
	balance_btc: number;
	address: IWalletAddresses[];
}

export interface IWalletAddresses {
	id: number;
	nickname: string;
	address: string;
	type: string;
	asset_id: number;
	balance_id: number;
	chain_id: number;
	chain: { name: string; code: string };
}

export interface IWalletAssetItem {
	id: number;
	code: string;
	type: string;
	depositable: 0 | 1;
	withdrawable: 0 | 1;
	depositable_message: null | string;
	withdrawable_message: null | string;
	decimals: null | number;
	name: string;
	deposit_max?: number;
	deposit_min?: number;
	withdraw_max?: number;
	withdraw_min?: number;
	exchange_min: number;
	img_path: string;
	exchangeable: 0 | 1;
	chains?: IWalletChains[];
}

export interface IWalletChains {
	id: number;
	name: string;
	code: string;
	pivot: IPivot;
}

export interface IPivot {
	asset_id: number;
	chain_id: number;
}

export interface IWalletBalance {
	btc: number;
	eur: number;
}

export interface IWalletsBalance {
	fiat: IWalletBalance;
	crypto: IWalletBalance;
}

export interface IWalletsData {
	wallets: IWalletItem[] | null;
	fiat: IWalletBalance;
	crypto: IWalletBalance;
}
export type IGetWalletsRequestPayload = IGetWalletsRequestPayloadData | undefined;
export type IWalletsResponsePayload = IWalletsData;
export type IWalletResponsePayload = IWalletItem;

export enum EWalletType {
	CRYPTO = 'crypto',
	FIAT = 'fiat',
}
