import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICryptoFilters } from 'components/DepositsWithdrawals/Crypto/types';
import {
	IDetailedViewCryptoDetails,
	IDetailedViewStore,
	IDetailedViewTradeDetails,
	IFileDownloadPayload,
	IDetailedViewFiatDetails,
	IFilters,
	IDetailedFiltersPayload,
} from './types';

// TODO: Add proper types
export const initialState: IDetailedViewStore = {
	loading: false,
	tradeDetails: null,
	fileLoading: false,
	cryptoDetails: null,
	fiatDetails: null,
	filters: null,
	query: null,
};

const detailedView = createSlice({
	name: '@@detailedView',
	initialState,
	reducers: {
		hideLoading: (state) => {
			const newState = state;
			newState.loading = false;
		},

		getTradeDetailsRequest: (state, { payload }: PayloadAction<number>) => {
			const newState = state;
			newState.loading = true;
		},
		getTradeDetailsSuccess: (state, { payload }: PayloadAction<IDetailedViewTradeDetails>) => {
			const newState = state;
			newState.tradeDetails = payload;
			newState.loading = false;
		},

		getCryptoDetailsRequest: (state, { payload }: PayloadAction<number>) => {
			const newState = state;
			newState.loading = true;
		},
		getCryptoDetailsSuccess: (state, { payload }: PayloadAction<IDetailedViewCryptoDetails>) => {
			const newState = state;
			newState.cryptoDetails = payload;
			newState.loading = false;
		},

		getFiatDetailsRequest: (state, { payload }: PayloadAction<number>) => {
			const newState = state;
			newState.loading = true;
		},
		getFiatDetailsSuccess: (state, { payload }: PayloadAction<IDetailedViewFiatDetails>) => {
			const newState = state;
			newState.fiatDetails = payload;
			newState.loading = false;
		},

		createTradeDetailsRequest: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.loading = true;
		},
		createTradeDetailsSuccess: (state, { payload }: PayloadAction<IDetailedViewTradeDetails>) => {
			const newState = state;
			newState.tradeDetails = payload;
			newState.loading = false;
		},

		createFiatDetailsRequest: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.loading = true;
		},
		createFiatDetailsSuccess: (state) => {
			const newState = state;
			newState.loading = false;
		},

		createCryptoDetailsRequest: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.loading = true;
		},
		createCryptoDetailsSuccess: (state) => {
			const newState = state;
			newState.loading = false;
		},

		downloadCryptoFileRequest: (state, { payload }: PayloadAction<IFileDownloadPayload>) => {
			const newState = state;
			newState.fileLoading = true;
		},

		downloadFiatFileRequest: (state, { payload }: PayloadAction<IFileDownloadPayload>) => {
			const newState = state;
			newState.fileLoading = true;
		},
		downloadTradeFileRequest: (state, { payload }: PayloadAction<IFileDownloadPayload>) => {
			const newState = state;
			newState.fileLoading = true;
		},
		setDetailedFilters: (state, { payload }: PayloadAction<IDetailedFiltersPayload>) => {
			const newState = state;
			newState.filters = payload.filters;
			newState.query = payload.query;
		},
		clearDetailedFilters: (state) => {
			const newState = state;
			newState.filters = null;
			newState.query = null;
		},
	},
});

export default detailedView.reducer;

export const {
	hideLoading,
	getTradeDetailsRequest,
	getTradeDetailsSuccess,
	createTradeDetailsRequest,
	createTradeDetailsSuccess,
	createFiatDetailsRequest,
	createFiatDetailsSuccess,
	createCryptoDetailsRequest,
	createCryptoDetailsSuccess,
	downloadCryptoFileRequest,
	downloadFiatFileRequest,
	downloadTradeFileRequest,
	getCryptoDetailsRequest,
	getCryptoDetailsSuccess,
	getFiatDetailsRequest,
	getFiatDetailsSuccess,
	setDetailedFilters,
	clearDetailedFilters,
} = detailedView.actions;
