/* eslint-disable @typescript-eslint/no-explicit-any */
import fileDefault from '../img/file-default.svg';
import filePdf from '../img/pdf_icon.svg';

export const handleUploadIcon = (type: string | undefined) => {
	switch (type) {
		case 'pdf':
			return filePdf;
		default:
			return fileDefault;
	}
};
