import React, { FC } from 'react';
import { useHistory } from 'react-router';
import Popup from 'reactjs-popup';
import { IPopUpData } from 'redux/reducers/popUp/types';
import { IPopUp } from 'layouts-elements/PopUp/types';

const ReferralsTransferSuccessPopUp: FC<IPopUp & IPopUpData> = ({
	open,
	closeModal,
	title,
	message,
}) => {
	const history = useHistory();

	const handleGoTo = () => {
		history.push('/wallets');
		closeModal();
	};
	return (
		<Popup open={open} closeOnDocumentClick onClose={closeModal}>
			<div className="popup">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<button type="button" className="popup__close-btn" onClick={closeModal} />
				<div className="popup-header popup-header--mb-20">
					<p className="popup-header__title">Transfer Successful</p>
				</div>
				<div className="popup-icon popup-icon--type2">
					<svg
						width="81"
						height="80"
						viewBox="0 0 81 80"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect x="1" y="0.5" width="79" height="79" rx="39.5" stroke="#5EC45C" />
						<rect x="21" y="20" width="40" height="40" rx="20" fill="#5EC45C" />
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M40.9998 30.6998C35.8636 30.6998 31.6998 34.8636 31.6998 39.9998C31.6998 45.1361 35.8636 49.2998 40.9998 49.2998C46.1361 49.2998 50.2998 45.1361 50.2998 39.9998C50.2998 34.8636 46.1361 30.6998 40.9998 30.6998ZM30.2998 39.9998C30.2998 34.0904 35.0904 29.2998 40.9998 29.2998C46.9093 29.2998 51.6998 34.0904 51.6998 39.9998C51.6998 45.9093 46.9093 50.6998 40.9998 50.6998C35.0904 50.6998 30.2998 45.9093 30.2998 39.9998Z"
							fill="white"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M46.4338 35.4504C46.7372 35.69 46.789 36.1301 46.5495 36.4336L40.2337 44.4336C40.109 44.5915 39.9224 44.6882 39.7214 44.6988C39.5205 44.7095 39.3247 44.6332 39.184 44.4894L35.4998 40.7247C35.2294 40.4484 35.2341 40.0052 35.5105 39.7348C35.7868 39.4644 36.2299 39.4692 36.5003 39.7455L39.628 42.9415L45.4506 35.5661C45.6902 35.2626 46.1304 35.2109 46.4338 35.4504Z"
							fill="white"
						/>
					</svg>
				</div>
				<div className="popup-text">
					<p>Referral Fee has been transferred to your wallet.</p>
				</div>
				<div className="popup-footer">
					<div className="popup-submit">
						<button onClick={handleGoTo} type="button" className="btn btn-primary btn--full">
							Go to Wallet
						</button>
					</div>
				</div>
			</div>
		</Popup>
	);
};

export default ReferralsTransferSuccessPopUp;
