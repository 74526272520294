import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDetailedFilters } from 'redux/reducers/detailedView/selectors';

import { Selected } from 'ui/Selected/Selected';
import CryptocurrencySelect from '../../../ui/CryptocurrencySelect/CryptocurrencySelect';
import { Datepicker } from './Datepicker/Datepicker';

const Filters: FC<any> = ({ filters, onChange }) => {
	const [resetSelected, setResetSelected] = useState(false);
	const filtersDetailed = useSelector(getDetailedFilters);
	const changeFilter = (value: any) => {
		onChange({ ...filters, ...value });
	};

	/// new
	const selectChangeCryptocurrencySell = (value: string | undefined) => {
		onChange({ ...filters, sell: value });
	};
	const selectChangeCryptocurrencyBuy = (value: string | undefined) => {
		onChange({ ...filters, buy: value });
	};
	// DataPicker
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const handleSelectDate = (value: [Date | null, Date | null]) => {
		setStartDate(value[0]);
		setEndDate(value[1]);
		onChange({ ...filters, date_from: value[0], date_to: value[1] });
	};

	const handleReset = () => {
		setResetSelected(!resetSelected);
		onChange({
			date_from: undefined,
			date_to: undefined,
			sell: undefined,
			buy: undefined,
			id: undefined,
		});
		setStartDate(null);
		setEndDate(null);
	};

	useEffect(() => {
		if (filtersDetailed?.date_from && filtersDetailed?.date_to) {
			setStartDate(new Date(filtersDetailed.date_from));
			setEndDate(new Date(filtersDetailed.date_to));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="table-filter">
			<div className="table-filter-left">
				<Datepicker
					value={[startDate === null ? null : startDate, endDate === null ? null : endDate]}
					onChange={handleSelectDate}
				/>
				<div className="select-block">
					<p className="select-block__name">Sell</p>
					<CryptocurrencySelect
						onSelectChange={selectChangeCryptocurrencySell}
						resetSelected={resetSelected}
						disableSearch
						withIcons
						selectedCurrency={filters.sell}
					/>
				</div>
				<div className="select-block select-block--mb-0">
					<p className="select-block__name">Buy</p>
					<CryptocurrencySelect
						onSelectChange={selectChangeCryptocurrencyBuy}
						resetSelected={resetSelected}
						disableSearch
						withIcons
						selectedCurrency={filters.buy}
					/>
				</div>
			</div>
			<div className="table-filter-right">
				<div className="input-wrapper">
					<input
						onChange={(e) => {
							onChange({ ...filters, id: e.target.value ? e.target.value : undefined });
						}}
						value={filters.id === undefined ? '' : filters.id}
						className="input-item"
						type="text"
						placeholder="Tx ID"
					/>
				</div>

				<button type="button" className="btn btn-reset" onClick={handleReset}>
					Reset
				</button>
			</div>
		</div>
	);
};

export default Filters;
