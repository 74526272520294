export interface ILayersData {
	isReversed: boolean;
	isReversedServer: boolean;
	data: Record<string, any>;
	hasBothPairs: boolean;
}

export const layersFilter = (tradePair: string | undefined, layers: any) => {
	if (tradePair && layers && Object.keys(layers).length) {
		const [oneText, twoText] = tradePair.split('_');

		const pair = `${oneText.toUpperCase()}/${twoText.toUpperCase()}`;
		const pairReverse = `${twoText.toUpperCase()}/${oneText.toUpperCase()}`;
		const pairSearch = Object.keys(layers).find((el) => el === pair);
		const pairReverseSearch = Object.keys(layers).find((el) => el === pairReverse);
		if (pairSearch && pairReverseSearch) {
			const data: ILayersData = {
				isReversed: false,
				isReversedServer: layers[pairSearch].reverse,
				data: layers[pairSearch].levels || null,
				hasBothPairs: true,
			};
			return data;
		}
		if (pairSearch) {
			const data: ILayersData = {
				isReversed: false,
				isReversedServer: layers[pairSearch].reverse,
				data: layers[pairSearch].levels || null,
				hasBothPairs: false,
			};
			// console.log(data);
			return data;
		}
		if (pairReverseSearch) {
			const data: ILayersData = {
				isReversed: true,
				isReversedServer: layers[pairReverseSearch].reverse,
				data: layers[pairReverseSearch].levels || null,
				hasBothPairs: false,
			};
			// console.log(data);
			return data;
		}
		return {};
	}
	return {};
};
