import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { APP_NAME } from 'services/constants/env';
import Content from 'layouts-elements/Content';
import Footer from 'layouts-elements/Footer';
import HeaderTop from 'layouts-elements/Header';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';

import { getFirstLoading, getUserData } from 'redux/reducers/auth/selectors';

import { createBrowserHistory } from 'history';
import { IAuth } from './types';
import {
	getUserRequest,
	setFirstLoading,
	unsetFirstLoading,
} from '../../redux/reducers/auth/reducer';
import { IGetUserRequest } from '../../redux/reducers/auth/types';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
// ==========================================:
const Auth: FC<IAuth> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';
	const login = useSelector(getUserData);
	const firstLoading = useSelector(getFirstLoading);
	const dispatch = useDispatch();

	const history = createBrowserHistory({ forceRefresh: true });

	useEffect(() => {
		if (login === null) {
			history.push('/login');
			dispatch(setFirstLoading());
		} else {
			dispatch(unsetFirstLoading());
		}
	}, [login, history, dispatch]);

	// useEffect(() => {
	// 	const params: IGetUserRequest = {
	// 		onFinally: (data) => {
	// 			if (data !== null) {
	// 				dispatch(unsetFirstLoading());
	// 			}
	// 		},
	// 	};
	// 	if (firstLoading) {
	// 		dispatch(getUserRequest(params));
	// 	}
	// }, [dispatch, firstLoading]);

	if (firstLoading) {
		return <LoadingScreen />;
	}

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>
			{/* <motion.div
				className="wrapper"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1, transition: { duration: 0.4 } }}
				exit={{ opacity: 0, transition: { duration: 0.4 } }}
			> */}
			{/* <HeaderTop /> */}
			{/* <Content>{children}</Content>
				<Footer />
			</motion.div> */}
			<div className="wrapper">
				{/* <HeaderTop /> */}
				<Content>{children}</Content>
				<Footer />
			</div>
		</>
	);
};

export default Auth;
