import { IStoreState } from '../../types';
import { IDetailedViewStore } from './types';

export const getDetailedView = (state: IStoreState): IDetailedViewStore => state.detailedView;

export const getDetailedViewLoading = (state: IStoreState) => {
	return getDetailedView(state).loading;
};

export const getTradeDetails = (state: IStoreState) => {
	return getDetailedView(state).tradeDetails;
};

export const getCryptoDetails = (state: IStoreState) => {
	return getDetailedView(state).cryptoDetails;
};

export const getFiatDetails = (state: IStoreState) => {
	return getDetailedView(state).fiatDetails;
};

export const getDetailedFilters = (state: IStoreState) => {
	return getDetailedView(state).filters;
};

export const getDetailedQuery = (state: IStoreState) => {
	return getDetailedView(state).query;
};
