import { FC } from 'react';
// import toastifyError from 'assets/img/icons/toastify_error.svg';
import toastifySuccess from 'assets/img/icons/toastify_success.svg';
import toastifyInfo from 'assets/img/icons/toastify_info.svg';
import toastifyErrorHeading from 'assets/img/icons/toast_error.svg';
import { useDispatch } from 'react-redux';
import { IToastifyComponent } from './types';
import { popUpOpen } from '../../redux/reducers/popUp/reducer';
// ==========================================:

const ToastifyComponent: FC<IToastifyComponent> = ({ message, toastProps, title }) => {
	const dispatch = useDispatch();

	return (
		<div className="toastify-component">
			{toastProps?.type === 'error' && (
				<>
					<div className="toastify-component__heading">
						<img src={toastifyErrorHeading} width="30" height="30" alt="" />
						<p>
							{title} {toastProps?.type === 'error' && `Error`}
						</p>
					</div>
					<div className="toastify-component__content">{message}</div>
					<button
						type="button"
						className="toastify-component__btn-light"
						/* onClick={() => {
							const supportButton = document.getElementById('footer_support_btn');
							supportButton.click();
							toastProps.closeToast();
						}} */
						onClick={() => {
							dispatch(popUpOpen('supportSuccess'));
						}}
					>
						Support
					</button>
				</>
			)}
			{toastProps?.type === 'user_blocked' && (
				<>
					<div className="toastify-component__heading">
						<img src={toastifyErrorHeading} width="30" height="30" alt="" />
						<p>{title}123</p>
					</div>
					<div className="toastify-component__content">{message}</div>
					<button
						type="button"
						className="toastify-component__btn-light"
						onClick={() => {
							dispatch(popUpOpen('supportSuccess'));
						}}
					>
						Support
					</button>
				</>
			)}
			{toastProps?.type === 'success' && (
				<div className="toastify-component__icon toastify-component__icon--success">
					<img src={toastifySuccess} width="30" height="30" alt="" />
				</div>
			)}
			{toastProps?.type === 'info' && (
				<div className="toastify-component__icon toastify-component__icon--info">
					<img src={toastifyInfo} width="30" height="30" alt="" />
				</div>
			)}
			{toastProps?.type !== 'error' && (
				<div className="toastify-component__content">
					<h3 className="toastify-component__title">
						{title}
						{/* {toastProps?.type === 'success' && `Successful`} */}
						{toastProps?.type === 'info' && `Information`}
					</h3>
					<p className="toastify-component__text">{message}</p>
				</div>
			)}
		</div>
	);
};

export default ToastifyComponent;
