import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';

import { IWalletItem, IWalletsStore, IWalletsBalance } from './types';

// ==========================================:
const getWalletsState = (state: IStoreState): IWalletsStore => state.wallets;
export const getWallets = createSelector([getWalletsState], (wallets: IWalletsStore) => wallets);

// ====================================================:
export const getWalletsIsLoad = createSelector(
	[getWallets],
	(wallets: IWalletsStore): boolean => wallets.walletsLoader,
);

// ====================================================:
export const getWalletsList = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IWalletItem[] | null => wallets.walletsList,
);

// ====================================================:
export const getWalletsFiatList = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IWalletItem[] | null =>
		wallets.walletsList ? wallets.walletsList.filter((el) => el.asset.type === 'fiat') : [],
);

// ====================================================:
export const getWalletsCryptoList = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IWalletItem[] | null =>
		wallets.walletsList ? wallets.walletsList.filter((el) => el.asset.type !== 'fiat') : [],
);

// ====================================================:
export const getWalletsBalance = createSelector(
	[getWallets],
	(wallets: IWalletsStore): IWalletsBalance | null => wallets.walletsBalance,
);

export const getWalletsLayers = createSelector(
	[getWallets],
	(wallets: IWalletsStore) => wallets.layers,
);
