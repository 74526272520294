import { FormikValues, useField } from 'formik';
import { ChangeEvent, FC, useEffect } from 'react';
import FormControl from 'ui/Formik/FormConrol';
import { IAdminInput } from '../Input/types';

// ==========================================:
const InputFloatingLabel: FC<IAdminInput & FormikValues> = (props) => {
	const {
		ariaLabel,
		type,
		placeholder,
		field,
		className,
		onKeyUp,
		onBlur,
		disabled,
		inputMode,
		readonly,
		setValue,
		id,
	} = props;

	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const phoneNumber = event.target.value.replace(/[^0-9]/g, '');
		!!setValue && setValue('phone', phoneNumber, true);
	};

	return (
		<FormControl ariaLabel={ariaLabel} {...props}>
			{type !== 'textarea' ? (
				<input
					className={`input-item input-item--floating ${className ? String(className) : ''}`}
					{...field}
					type={type}
					placeholder=" "
					onKeyUp={onKeyUp}
					disabled={disabled}
					inputMode={inputMode || null}
					onWheel={(e) => e.currentTarget.blur()}
					readOnly={!!readonly}
					// onChange={field.name === 'phone' ? handlePhoneChange : field.onChange}
					onChange={field.onChange}
					onBlur={field.onBlur}
					id={id}
				/>
			) : (
				<textarea
					className={`input-item input-item--floating textarea_item ${
						className ? String(className) : ''
					}`}
					{...field}
					type={type}
					placeholder={placeholder}
					onKeyUp={onKeyUp}
					inputMode={inputMode || null}
					id={id}
				/>
			)}
			<label htmlFor={id}>{placeholder}</label>
		</FormControl>
	);
};

export default InputFloatingLabel;
