import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from '../../types';
import { IReferralsStore, IReferralsWallet } from './types';

const getReferralsState = (state: IStoreState): IReferralsStore => state.referrals;
export const getReferrals = createSelector(
	[getReferralsState],
	(referrals: IReferralsStore) => referrals,
);

export const getReferralsWallets = createSelector(
	[getReferrals],
	(referrals: IReferralsStore): IReferralsWallet[] | null => referrals.wallets,
);
