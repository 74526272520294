import { CloseFileIcon } from 'assets/inline-svg';
import { handleUploadIcon } from 'layouts-elements/DropFileCustom/config/ImageConfig';
import React, { useState, DragEvent, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface IInputFile {
	fileList: any;
	setFileList: React.Dispatch<any>;
}

const InputFile: React.FC<IInputFile> = ({ fileList, setFileList }) => {
	const [drag, setDrag] = useState(false);
	const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(true);
	};
	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(false);
	};
	const handleOnDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const updatedList = [...fileList, ...e.dataTransfer.files];
		setFileList(updatedList);
		setDrag(false);
	};

	const onFileDrop = (e: any) => {
		const newFile = e.target.files[0];
		if (newFile) {
			const updatedList = [...fileList, newFile];
			setFileList(updatedList);
		}
	};

	const fileRemove = (file: any) => {
		const updatedList = [...fileList];
		updatedList.splice(fileList?.indexOf(file), 1);
		setFileList(updatedList);
	};
	return (
		<>
			<div
				className={`input-file${drag ? ' active' : ''}`}
				onDragStart={handleDragStart}
				onDragLeave={handleDragLeave}
				onDragOver={handleDragStart}
				onDrop={handleOnDrop}
			>
				<label className="input-file__button">
					<input type="file" value="" onChange={onFileDrop} className="hidden" />
					<span className="verification-upload__button-content">
						<svg
							width="80"
							height="80"
							viewBox="0 0 80 80"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<rect x="0.5" y="0.5" width="79" height="79" rx="39.5" stroke="#5974FF" />
							<rect x="20.5" y="20" width="40" height="40" rx="20" fill="#5974FF" />
							<path
								d="M33 48.25C32.5858 48.25 32.25 47.9142 32.25 47.5L32.25 40C32.25 39.5858 31.9142 39.25 31.5 39.25C31.0858 39.25 30.75 39.5858 30.75 40L30.75 47.5C30.75 48.7426 31.7574 49.75 33 49.75L48 49.75C49.2426 49.75 50.25 48.7426 50.25 47.5L50.25 40C50.25 39.5858 49.9142 39.25 49.5 39.25C49.0858 39.25 48.75 39.5858 48.75 40L48.75 47.5C48.75 47.9142 48.4142 48.25 48 48.25L33 48.25Z"
								fill="white"
							/>
							<path
								d="M45.0877 41.466C45.345 41.1414 45.2905 40.6697 44.9659 40.4123C44.6414 40.155 44.1696 40.2095 43.9123 40.534L42.5144 42.297C41.9562 43.001 41.5726 43.483 41.25 43.8105L41.25 31C41.25 30.5858 40.9142 30.25 40.5 30.25C40.0858 30.25 39.75 30.5858 39.75 31L39.75 43.8105C39.4273 43.483 39.0437 43.001 38.4855 42.297L37.0877 40.534C36.8303 40.2095 36.3586 40.155 36.034 40.4123C35.7094 40.6697 35.6549 41.1414 35.9123 41.466L37.3386 43.2648C37.8717 43.9373 38.3117 44.4922 38.7055 44.8875C39.1096 45.2932 39.5581 45.6294 40.1313 45.7208C40.2534 45.7402 40.3766 45.75 40.5 45.75C40.6234 45.75 40.7465 45.7402 40.8686 45.7208C41.4418 45.6294 41.8903 45.2932 42.2945 44.8875C42.6882 44.4922 43.1282 43.9373 43.6614 43.2648L45.0877 41.466Z"
								fill="white"
							/>
						</svg>

						<span>
							<b>Click to upload</b> or drag and drop files
						</span>
					</span>
				</label>
			</div>
			{fileList.length > 0 ? (
				<div className="input-file-preview">
					{fileList.map((item: any) => (
						<div key={uuidv4()} className="input-file-preview__item">
							<img src={handleUploadIcon(item.type.split('/')[1])} alt="" />
							<span className="input-file-preview__name" title={item.name}>
								{item.name}
							</span>
							<span className="drop-file-preview__item__del" onClick={() => fileRemove(item)}>
								<CloseFileIcon />
							</span>
						</div>
					))}
				</div>
			) : null}
		</>
	);
};

export default InputFile;
