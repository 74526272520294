import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'layouts-elements/Loading/Loading';

import { getUserData } from 'redux/reducers/auth/selectors';
import { getCryptoDetailsRequest } from 'redux/reducers/detailedView/reducer';
import { getCryptoDetails, getDetailedViewLoading } from 'redux/reducers/detailedView/selectors';

import { getUserRequest } from 'redux/reducers/auth/reducer';
import { getCryptoHistoryList } from '../../../redux/reducers/transactions/selectors';
import { IGetTransactionHistoryRequestPayload } from '../../../redux/reducers/transactions/types';
import { getCryptoHistoryRequest } from '../../../redux/reducers/transactions/reducer';
import { roundingNumber } from '../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';
import { statusNames } from '../../TransactionHistory/utils';
import DetailedViewCryptoForm from '../DetailedViewForm/DetailedViewCryptoForm';
import DefaultFields from '../DefaultFields/DefaultFields';

export interface IDepositHistoryCryptoDetails {
	txId: string | number;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const DepositHistoryCryptoDetails: FC<IDepositHistoryCryptoDetails> = ({ txId }) => {
	const dispatch = useDispatch();
	const item = useSelector(getCryptoDetails);
	const loading = useSelector(getDetailedViewLoading);

	useEffect(() => {
		if (txId) {
			dispatch(getCryptoDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId]);

	if (item && !loading) {
		return (
			<div className="detailed-view">
				<div className="input-wrap--col-2">
					<DefaultFields item={item} userData={item.user} />

					<CustomInput
						label="Currency"
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						value={`${item.asset.code.toUpperCase()} ${item.asset.name}`}
						type="text"
						disabled
					/>
					<CustomInput
						label="Gross"
						value={`${roundingNumber(
							item.gross_fee,
							item.asset.code,
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						)} ${item.asset.code.toUpperCase()}`}
						type="text"
						disabled
					/>

					<CustomInput
						label="Fee"
						value={`${roundingNumber(
							item.fee,
							item.asset.code,
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						)} ${item.asset.code.toUpperCase()}`}
						type="text"
						disabled
					/>
					<CustomInput
						label="Net"
						value={`${toFixedNumber(
							item.net_fee,
							item.asset.code,
							true,
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						)} ${item.asset.code.toUpperCase()}`}
						type="text"
						disabled
					/>

					{/* {item.status === 'admin_pending' ||
										(item.status === 'pending' && (
											<CustomInput
												label="Type"
												// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
												value={`${item.type}`}
												type="text"
												disabled
											/>
										))} */}
					<CustomInput
						label="Destination"
						value={
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							item.description || item.destination || item.address.address || '-'
						}
						type="text"
						disabled
					/>
					<CustomInput
						label="Tx hash"
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						value={`${item.ext_tx_hash ? item.ext_tx_hash : '-'}`}
						type="text"
						disabled
					/>
					{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
					<CustomInput label="Tx ID" value={`${item.id}`} type="text" disabled />
					<CustomInput label="Status" value={`${statusNames[item.status]}`} type="text" disabled />
					<CustomInput
						label="Description"
						value={
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							item.description || item.destination || item.address.address || '-'
						}
						type="text"
						disabled
					/>
					<CustomInput
						label="Reference"
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						value={`${item.reference ? item.reference : item.ext_tx_hash || '-'}`}
						type="text"
						disabled
					/>
				</div>
				{item && <DetailedViewCryptoForm item={item} />}
			</div>
		);
	}

	if (!item && !loading) {
		return <div>No data.</div>;
	}

	return <Loading />;
};

export default DepositHistoryCryptoDetails;
