import { FC } from 'react';
// import toastifyError from 'assets/img/icons/toastify_error.svg';
import toastifyErrorHeading from 'assets/img/icons/toast_error.svg';
import { useDispatch } from 'react-redux';
import { IToastifyComponent } from '../types';
import { popUpOpen } from '../../../redux/reducers/popUp/reducer';
// ==========================================:

const AccountNotFound: FC<IToastifyComponent> = ({ message, toastProps, title }) => {
	const dispatch = useDispatch();
	return (
		<>
			<div className="toastify-component">
				<div className="toastify-component__heading">
					<img src={toastifyErrorHeading} width="30" height="30" alt="" />
					<p>{title}</p>
				</div>
				<div className="toastify-component__content">{message}</div>
				<button
					type="button"
					className="toastify-component__btn-light"
					onClick={() => {
						dispatch(popUpOpen('supportSuccess'));
					}}
				>
					Support
				</button>
			</div>
		</>
	);
};

export default AccountNotFound;
