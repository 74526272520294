/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, DragEvent, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { verificationFilesRequest, verificationFilesSave } from 'redux/reducers/settings/reducer';
import { useHistory, useLocation } from 'react-router';
import { handleUploadIcon } from 'layouts-elements/DropFileCustom/config/ImageConfig';
import { CloseFileIcon } from 'assets/inline-svg';
import { getSettingsState } from 'redux/reducers/settings/selectors';
import ProgressBar from 'components/ProgressBar/ProgressBar';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import IconSvg from 'ui/Svg/IconSvg';
import PopUp from '../../../layouts-elements/PopUp/PopUp';
import { getUserData } from '../../../redux/reducers/auth/selectors';
import WalletSideBar from '../../Wallets/WalletSideBar/WalletSideBar';
import { dataURLtoBlob, statusNames } from './utiils';
import { popUpOpen } from '../../../redux/reducers/popUp/reducer';
import {
	getUserVerificationsRequest,
	getverificationFiles,
} from '../../../redux/reducers/auth/reducer';

const DropFileInput = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const userData = useSelector(getUserData);
	const [fileList, setFileList] = useState<any>([]);
	const [drag, setDrag] = useState(false);
	const userType = useSelector(getUserData)?.type;

	const { uploadProgress, verificationFilesLoader, files } = useSelector(getSettingsState);
	const handleDragStart = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(true);
	};
	const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		setDrag(false);
	};
	const handleOnDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		const updatedList = [...fileList, ...e.dataTransfer.files];
		setFileList(updatedList);
		setDrag(false);
	};

	const onFileDrop = (e: any) => {
		const newFile = e.target.files[0];
		if (newFile) {
			const updatedList = [...fileList, newFile];
			setFileList(updatedList);
		}
	};

	const fileRemove = (file: any) => {
		const updatedList = [...fileList];
		updatedList.splice(fileList?.indexOf(file), 1);
		setFileList(updatedList);
	};
	const submitFile = () => {
		if (!fileList.length) return;
		const fd = new FormData();
		const ins = fileList.length;
		for (let x = 0; x < ins; x += 1) {
			fd.append(`files[${x}]`, fileList[x]);
		}
		dispatch(verificationFilesRequest({ files: fd, history }));
		// dispatch(popUpOpen('ondatoPopUp'));
	};

	useEffect(() => {
		if (userData && statusNames[userData?.status_id] === 'Verified') {
			history.push('/account-settings');
		}
	}, [history, userData]);

	// Update user status if url has query ?status=pending
	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const param = searchParams.get('status');
		if (param && param === 'pending') {
			dispatch(getverificationFiles());
		}
	}, [dispatch, location.search]);

	const handleStorageFilesSave = useCallback(
		async (fileArr: File[]) => {
			const filesDataArray = await Promise.all(
				fileArr.map(async (file) => {
					return new Promise<any>((resolve) => {
						const reader = new FileReader();
						reader.onload = (event) => {
							if (event.target) {
								const photoData = event.target.result;
								resolve({ name: file.name, type: file.type, data: photoData });
							}
						};
						reader.readAsDataURL(file);
					});
				}),
			);

			if (filesDataArray.length > 0) {
				dispatch(verificationFilesSave(filesDataArray));
			}
		},
		[dispatch],
	);

	useEffect(() => {
		// Restore files on page load handler
		/* if (files.length > 0) {
			const restoredFiles = files.map((photoData: any) => {
				const blob = dataURLtoBlob(photoData.data);
				return new File([blob], photoData.name, { type: photoData.type });
			});
			setFileList(restoredFiles);
		} */
		// End Restore files on page load handler

		setFileList([]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (fileList.length === 0) {
			dispatch(verificationFilesSave([]));
		}
		const saveFilesAndHandle = async () => {
			await handleStorageFilesSave(fileList);
		};
		// eslint-disable-next-line @typescript-eslint/no-floating-promises
		saveFilesAndHandle();
	}, [fileList, handleStorageFilesSave, dispatch]);

	return (
		<>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					{!!userData && (
						<motion.div
							className="wallet-content"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1, transition: { duration: 0.4 } }}
							exit={{ opacity: 0, transition: { duration: 0.4 } }}
						>
							{/* {statusNames[userData?.status_id] === 'Verified' && (
								<div className="wallet-content-header">
									<div className="wallet-content-header__title">
										<p>You are already verified</p>
									</div>
								</div>
							)}
							{statusNames[userData?.status_id] === 'Pending' && (
								<div className="wallet-content-header">
									<div className="wallet-content-header__title">
										<p>Your documents are pending</p>
									</div>
								</div>
							)}
							{statusNames[userData?.status_id] === 'Rejected' && (
								<div className="wallet-content-header">
									<div className="wallet-content-header__title">
										<p>Your documents are rejected</p>
									</div>
								</div>
							)} */}

							<>
								<div className="wallet-content-header">
									<div className="wallet-content-header__title">
										<p>Verification</p>
									</div>
								</div>

								{statusNames[userData?.status?.name] === 'Unverified' && (
									<div className="tabs-buttons--type2">
										<button
											type="button"
											className={userType === 'individual' ? 'active' : 'disabled'}
										>
											Individual Account
										</button>
										<button
											type="button"
											className={userType !== 'individual' ? 'active' : 'disabled'}
										>
											Corporate Account
										</button>
									</div>
								)}

								<div className="setting-content setting-content--rounded setting-content--shadow gap-0">
									{statusNames[userData?.status?.name] === 'Unverified' && (
										<>
											<div className="verification-form">
												<div className="verification-item">
													<div className="verification-upload-info">
														<p>Documents to Upload</p>
														{userType !== 'individual' ? (
															<>
																<ul>
																	<li>Corporate Structure Chart</li>
																	<li>Certificate of Incorporation</li>
																	<li>Register of Directors / Office Holders</li>
																	<li>Register of Shareholders</li>
																	<li>Bank Statement (Dated Within 3 Months)</li>
																	<li>
																		KYB form PDF file
																		<br />
																		{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
																		<a
																			href="https://drive.google.com/file/d/1SwauYAB_FFM-1_Kj4oNBJdE0UBqLSMTW/view?usp=drive_link"
																			target="_blank"
																			rel="noreferrer"
																			className="btn btn-outline btn-outline--pdf"
																		>
																			Download KYB-form.pdf
																		</a>
																	</li>
																</ul>
															</>
														) : (
															<>
																<ul>
																	<li>Proof of Address (Utility Bill – Dated Within 3 Months).</li>
																	<li>Bank Statement (Dated Within 3 Months).</li>
																	<li>Filled KYC form.</li>
																</ul>
																<p className="text-Grayscale-Gray-20 font-weight-normal mt-10 mb-10">
																	As a part of our compliance with international regulations and our
																	dedication to safeguarding your financial transactions, we require
																	certain personal information through the KYC (Know Your Customer)
																	process.
																</p>
																<a
																	href="https://drive.google.com/file/d/16YYNmUolgnIhpdkN_IcPrM7BogTWHyiu/view?usp=drive_link"
																	target="_blank"
																	rel="noreferrer"
																	className="btn btn-primary btn--small"
																>
																	Download KYC form
																</a>
															</>
														)}
													</div>
													<div className="verification-acount-info">
														{userType !== 'individual' ? (
															<>
																<div className="verification-acount-info__heading">
																	<b>Important:</b>
																</div>
																<ul className="verification-acount-info__list">
																	<li>
																		Bank Statements & Utility Bills MUST be dated within 3 months.
																	</li>
																	<li>
																		Register of Office Holders & Register of Directors MUST be dated
																		within 12 months.
																	</li>
																	<li>
																		If there are additional corporate entities within your structure
																		please upload the same documents for each entity.
																	</li>
																	<li>
																		KYB form PDF file:
																		<br />
																		Download PDF-file → Fill the document → Upload back.
																	</li>
																</ul>
															</>
														) : (
															<div className="verification-acount-info__heading">
																<span>
																	<b>Important:</b> Bank Statements & Utility Bills MUST be dated
																	within 3 months
																</span>
															</div>
														)}
													</div>
												</div>
												<div className="verification-item">
													<>
														<div
															className={`verification-upload${drag ? ' active' : ''}`}
															onDragStart={handleDragStart}
															onDragLeave={handleDragLeave}
															onDragOver={handleDragStart}
															onDrop={handleOnDrop}
														>
															{!verificationFilesLoader ? (
																<label className="verification-upload__button">
																	<input
																		className="hidden"
																		type="file"
																		value=""
																		onChange={onFileDrop}
																	/>
																	<span className="verification-upload__button-content">
																		<IconSvg name="upload" />
																		<span>
																			<b>Click to upload</b> or drag and drop files
																		</span>
																	</span>
																</label>
															) : (
																<ProgressBar progress={uploadProgress} />
															)}

															{/* <div className="verification-upload__drop">
															<p>Drag & Drop Files</p>
														</div> */}
														</div>
													</>
												</div>
											</div>
											<div className="verification-form">
												<div />
												{fileList.length > 0 && !verificationFilesLoader ? (
													<div className="drop-file-preview">
														{fileList.map((item: any) => (
															<div key={uuidv4()} className="drop-file-preview__item">
																<img src={handleUploadIcon(item.type.split('/')[1])} alt="" />
																<span className="drop-file-preview__name" title={item.name}>
																	{`${item.name.slice(0, 8)}...`}
																</span>
																<span
																	className="drop-file-preview__item__del"
																	onClick={() => {
																		fileRemove(item);
																	}}
																>
																	<CloseFileIcon />
																</span>
															</div>
														))}
													</div>
												) : null}
											</div>

											<div className="verification-form__buttons">
												<button
													onClick={() => {
														history.push('/account-settings');
													}}
													type="button"
													className="btn btn-cancel"
												>
													Cancel
												</button>
												<button
													type="button"
													className="btn btn-primary"
													onClick={submitFile}
													disabled={
														(userType === 'individual'
															? !(fileList.length === 3)
															: !(fileList.length === 6)) || verificationFilesLoader
													}
												>
													Confirm
												</button>
											</div>
										</>
									)}
									{statusNames[userData?.status?.name] === 'Pending' && (
										<div className="verification-item">
											<div className="verification-pending">
												<p>
													Your documents were received. In order to complete the verification
													process, please proceed by clicking the <span>“Start verification“</span>{' '}
													button. Kindly ensure you have your ID or Passport ready.
												</p>
												<p>
													Please do not hesitate to contact us at{' '}
													<a target="blank" href="mailto:support@kaiserex.com">
														support@kaiserex.com
													</a>{' '}
													if you have any questions.
												</p>
												<Link
													to={{ pathname: process.env.REACT_APP_VERIFICATION_URL }}
													target="_blank"
													className="verification-item verification-item__link"
												>
													<button type="button" className="btn btn--small-type2 btn-primary">
														Start Verification
													</button>
												</Link>
											</div>
										</div>
									)}
								</div>

								{/* {statusNames[userData?.status?.name] === 'Pending' && (
									<div className="setting-content setting-content--rounded setting-content--shadow mt-20">
										<div className="verification-item">
											<div className="verification-pending">
												{userType === 'individual' ? (
													<>
														<p>
															As a part of our compliance with international regulations and our
															dedication to safeguarding your financial transactions, we require
															certain personal information through the KYC (Know Your Customer)
															process.
														</p>
														<a
															href="https://drive.google.com/file/d/16YYNmUolgnIhpdkN_IcPrM7BogTWHyiu/view?usp=drive_link"
															target="_blank"
															rel="noreferrer"
															className="verification-item verification-item__link"
														>
															<button type="button" className="btn btn--small-type2 btn-primary">
																Download KYC form
															</button>
														</a>
													</>
												) : (
													<>
														<p>
															As part of our compliance with international regulations and our
															dedication to safeguarding your financial transactions, we require
															certain documents through the KYB (Know Your Business) process.
														</p>
														<a
															href="https://drive.google.com/file/d/1SwauYAB_FFM-1_Kj4oNBJdE0UBqLSMTW/view?usp=drive_link"
															target="_blank"
															rel="noreferrer"
															className="verification-item verification-item__link"
														>
															<button type="button" className="btn btn--small-type2 btn-primary">
																Download KYB form
															</button>
														</a>
													</>
												)}
											</div>
										</div>
									</div>
								)} */}
							</>
						</motion.div>
					)}
				</div>
			</section>

			{/* <PopUp /> */}
		</>
	);
};

export default DropFileInput;
