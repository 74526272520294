import { IReferralsApi } from './types';
import { http } from '../../http';
import { endpoint } from '../../endpoint';

export const referrals: IReferralsApi = {
	getReferralsWallets: () =>
		http.get(endpoint.referrals.GET_REFERRALS_WALLETS).then(({ data }) => data),
	postReferralsWalletTransfer: (payload) =>
		http.post(endpoint.referrals.POST_REFERRALS_WALLET_TRANSFER, payload).then(({ data }) => data),
};
