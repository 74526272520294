import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IDownloadKeyResponse,
	IDownloadPayload,
	IDownloadStore,
	ISupportRequestPayload,
} from './types';
// ==========================================:
export const initialState: IDownloadStore = {
	downloadKey: '',
	downloadKeyLoading: false,
	//--------------------
	downloadDepositsWithdraw: null,
	downloadDepositsWithdrawLoading: false,
	//--------------------
	downloadTrade: null,
	downloadTradeLoading: false,
	//-----------
	support: null,
	supportLoading: false,
};
// ==========================================:
const download = createSlice({
	name: '@@download',
	initialState,
	reducers: {
		downloadKeyRequest: (state) => {
			const newState = state;
			newState.downloadKeyLoading = true;
		},
		downloadKeySuccess: (state, action: PayloadAction<IDownloadKeyResponse>) => {
			const { payload } = action;
			const newState = state;
			newState.downloadKeyLoading = false;
			newState.downloadKey = payload.secret;
		},
		/// ////////////////////////////
		downloadDepositWithdrawsRequest: (state, action: PayloadAction<IDownloadPayload>) => {
			const newState = state;
			newState.downloadDepositsWithdrawLoading = true;
		},
		downloadDepositWithdrawsSuccess: (state, action: PayloadAction<IDownloadKeyResponse>) => {
			const { payload } = action;
			const newState = state;
			newState.downloadDepositsWithdrawLoading = false;
			newState.downloadDepositsWithdraw = payload;
		},
		/// ////////////////////////////
		downloadTradeRequest: (state, action: PayloadAction<IDownloadPayload>) => {
			const newState = state;
			newState.downloadTradeLoading = true;
		},
		downloadTradeSuccess: (state, action: PayloadAction<IDownloadKeyResponse>) => {
			const { payload } = action;
			const newState = state;
			newState.downloadTradeLoading = false;
			newState.downloadTrade = payload;
		},
		/// ////////////////////////////
		supportRequest: (state, action: PayloadAction<ISupportRequestPayload>) => {
			const newState = state;
			newState.supportLoading = true;
		},
		supportSuccess: (state) => {
			const newState = state;
			newState.supportLoading = false;
		},
		downloadInitState: () => initialState,
	},
});

export default download.reducer;
export const {
	supportRequest,
	supportSuccess,
	downloadKeyRequest,
	downloadKeySuccess,
	downloadInitState,
	downloadDepositWithdrawsRequest,
	downloadDepositWithdrawsSuccess,
	downloadTradeRequest,
	downloadTradeSuccess,
} = download.actions;
