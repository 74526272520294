// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import WalletSideBar from '../Wallets/WalletSideBar/WalletSideBar';
import { toFixedNumber } from '../../services/utils/toFixedNumber';
import { popUpOpen } from '../../redux/reducers/popUp/reducer';
import {
	referralsWalletsRequest,
	referralsWalletTransferRequest,
} from '../../redux/reducers/referrals/reducer';
import { getReferralsWallets } from '../../redux/reducers/referrals/selectors';
import {
	IPostReferralsWalletTransfer,
	IReferralsWallet,
} from '../../redux/reducers/referrals/types';
import { notificationContainer } from '../../services/utils/notificationContainer';

const Referrals = () => {
	const dispatch = useDispatch();
	const referralsWallets = useSelector(getReferralsWallets);

	const transferToWalletHandler = (e: React.MouseEvent<HTMLElement>, data: IReferralsWallet) => {
		(e.target as HTMLInputElement).disabled = true;
		const payload: IPostReferralsWalletTransfer = {
			apiParams: {
				balance_id: data.id,
			},
			onFinally: (hasError: boolean, status: boolean, message?: string) => {
				(e.target as HTMLInputElement).disabled = false;
				if (!hasError && status) {
					dispatch(popUpOpen('referralsTransferSuccessPopUp'));
				}
				if (!status && message) {
					notificationContainer(message, 'error');
				}
				if (hasError) {
					notificationContainer('Something went wrong. Please try again later.', 'error');
				}
			},
		};
		dispatch(referralsWalletTransferRequest(payload));
	};

	useEffect(() => {
		dispatch(referralsWalletsRequest());
	}, [dispatch]);

	return (
		<section className="profile-section">
			<div className="page-wrap">
				<WalletSideBar />
				<motion.div
					className="profile-content"
					initial={{ opacity: 0 }}
					animate={{ opacity: 1, transition: { duration: 0.4 } }}
					exit={{ opacity: 0, transition: { duration: 0.4 } }}
				>
					<div className="referrals__heading mb-8">Referrals</div>
					{!!referralsWallets && (
						<div className="referrals__box">
							{/* <p className="referrals__box-title mb-16">Referred Clients</p>
							<p className="referrals__box-count">35</p>
							<div className="referrals__box-divider" /> */}
							<p className="referrals__box-title mb-16">Referral Fees</p>
							<div className="referrals-table">
								{referralsWallets.map((item) => (
									<React.Fragment key={item.id}>
										<div className="referrals-table__item">
											<div className="trade-wallet-balances-table__coin">
												<img
													src={`/img/currencies/${item.asset.code.toLowerCase()}.svg`}
													alt={item.asset.name}
												/>
												<div className="trade-wallet-balances-table__text">
													{item.asset.name}
													<span>{item.asset.code.toUpperCase()}</span>
												</div>
											</div>

											<div className="referrals-table__item-right">
												{/* <p>{toFixedNumber(item.balance, item.asset.code)}</p> */}
												<p>{item.balance}</p>
												<button
													disabled={item.balance === 0}
													type="button"
													className="btn btn-primary btn--small"
													onClick={(e) => transferToWalletHandler(e, item)}
												>
													Transfer to Wallet
												</button>
											</div>
										</div>
									</React.Fragment>
								))}
							</div>
						</div>
					)}
				</motion.div>
			</div>
		</section>
	);
};

export default Referrals;
