/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { getTokenRefreshTime } from 'services/utils/tokenRefresh';
import {
	IAuthStore,
	ICreateNewPasswordPayload,
	ILoginPayload,
	ILoginResponse,
	ILogoutPayload,
	IResetPasswordPayload,
	ITokenRefreshResponse,
	ILoginResponseUser,
	IResetTwoFaRequestPayload,
	ICodeForTwoFaRequest,
	ILoginStep1Payload,
	IGetUserRequest,
} from './types';

// ==========================================:
export const initialState: IAuthStore = {
	userData: null,
	accessToken: null,
	socketToken: null,
	refreshToken: null,
	isAuthenticated: false,
	registrLoader: false,
	logoutLoader: false,
	loginLoader: false,
	forgotPasswordLoader: false,
	createNewPasswordLoader: false,
	ipConfirmLoader: false,
	emailConfirm: false,
	emailConfirmLoader: false,
	emailResetConfirmTokenLoader: false,
	emailResetConfirmTokenMessage: false,
	generateSmsLoader: false,
	tokenRefreshTime: null,
	tokenUpdating: false,
	firstLoading: true,
};

// ==========================================:
const auth = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		getUserRequest: (state, { payload }: PayloadAction<IGetUserRequest>) => {
			state.loginLoader = true;
		},
		getUserSuccess: (state, { payload }: PayloadAction<ILoginResponseUser>) => {
			state.loginLoader = false;
			state.userData = payload;
		},
		unsetFirstLoading: (state) => {
			state.firstLoading = false;
		},
		setFirstLoading: (state) => {
			state.firstLoading = true;
		},
		loginRequestStep1: (state, action: PayloadAction<ILoginStep1Payload>) => {
			const loginRequestState = state;
			loginRequestState.loginLoader = true;
		},
		hideLoader: (state) => {
			const loginRequestState = state;
			loginRequestState.loginLoader = false;
		},
		loginRequest: (state, action: PayloadAction<ILoginPayload>) => {
			const loginRequestState = state;

			loginRequestState.loginLoader = true;
		},
		loginSuccess: (state, action: PayloadAction<ILoginResponse>) => {
			const { payload } = action;
			const loginState = state;
			loginState.isAuthenticated = true;
			loginState.loginLoader = false;
			loginState.accessToken = payload.token;
			loginState.socketToken = payload.socket_token;
			loginState.userData = payload.user_data;
			// loginState.tokenRefreshTime = getTokenRefreshTime();
		},
		logoutRequest: (state, action: PayloadAction<ILogoutPayload>) => {
			const loginRequestState = state;
			loginRequestState.logoutLoader = true;
		},
		refreshTokenRequest: (state) => {
			const loginRequestState = state;
			loginRequestState.tokenUpdating = true;
			loginRequestState.logoutLoader = true;
		},
		refreshTokenSuccess: (state, action: PayloadAction<ITokenRefreshResponse>) => {
			const { payload } = action;
			const loginState = state;
			loginState.tokenUpdating = false;
			loginState.accessToken = payload.token;
			loginState.tokenRefreshTime = getTokenRefreshTime();
		},
		resetPasswordRequest: (state, action: PayloadAction<IResetPasswordPayload>) => {},
		createPasswordRequest: (state, action: PayloadAction<ICreateNewPasswordPayload>) => {},
		getTwoFaStatusRequest: (state, action) => {},
		getTwoFaStatusResponse: (state, action) => {},
		resetTwoFaRequest: (state, action: PayloadAction<IResetTwoFaRequestPayload>) => {},
		generateCodeForTwoFaRequest: (state, action: PayloadAction<ICodeForTwoFaRequest>) => {},
		getverificationFiles: (state) => {
			if (state.userData) {
				state.userData.status.name = 'pending';
			}
		},
		disableTwoFaRequestSuccess: (state) => {
			const stateData = state;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			stateData.userData.google2fa_enabled = 0;
		},
		enableTwoFaSuccess: (state) => {
			const stateData = state;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			stateData.userData.google2fa_enabled = 1;
		},
		authInitState: () => initialState,
		getUserVerificationsRequest: (state, action: PayloadAction<string | number>) => {},
		getUserVerificationsSuccess: (state, { payload }: PayloadAction<any>) => {
			const stateData = state;
			if (stateData.userData) {
				stateData.userData.status = { ...payload.status };
				stateData.userData.status_id = payload.status.id;
			}
		},
	},
});

export default auth.reducer;
export const {
	loginRequestStep1,
	loginRequest,
	loginSuccess,
	logoutRequest,
	authInitState,
	refreshTokenRequest,
	refreshTokenSuccess,
	resetPasswordRequest,
	createPasswordRequest,
	getUserRequest,
	getUserSuccess,
	getTwoFaStatusRequest,
	getTwoFaStatusResponse,
	resetTwoFaRequest,
	generateCodeForTwoFaRequest,
	getverificationFiles,
	disableTwoFaRequestSuccess,
	enableTwoFaSuccess,
	hideLoader,
	unsetFirstLoading,
	setFirstLoading,
	getUserVerificationsRequest,
	getUserVerificationsSuccess,
} = auth.actions;
