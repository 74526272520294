import { useHistory } from 'react-router';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setDetailedFilters } from 'redux/reducers/detailedView/reducer';
import { IFilters } from 'redux/reducers/detailedView/types';
import { ICryptoFilters } from 'components/DepositsWithdrawals/Crypto/types';
import TradeHistoryDetails from './TradeHistoryDetails/TradeHistoryDetails';
import DepositHistoryCryptoDetails from './DepositHistoryCryptoDetails/DepositHistoryCryptoDetails';
import DepositHistoryFiatDetails from './DepositHistoryFiatDetails/DepositHistoryFiatDetails';
import WithdrawalHistoryFiatDetails from './WithdrawalHistoryFiatDetails/WithdrawalHistoryFiatDetails';

export interface IStateLocation {
	txId: string;
	fromTradeHistory: boolean;
	fromTrade: boolean;
	fromDepositHistoryCrypto: boolean;
	fromWithdrawalHistoryCrypto: boolean;
	fromDepositFiatHistory: boolean;
	fromWithdrawalFiatHistory: boolean;
	fromStatementReports?: boolean;
	fromStatementTrade?: boolean;
	fromStatementDepositCrypto?: boolean;
	fromStatementDepositFiat?: boolean;
	fromStatementWithdrawalCrypto?: boolean;
	fromStatementWithdrawalFiat?: boolean;
	filters: IFilters | ICryptoFilters;
	query: {
		currentPage: number;
		perPage: number;
	};
}

const DetailedView = () => {
	const { state, pathname } = useLocation<IStateLocation>();
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		if (!state) {
			history.replace(pathname.substring(0, pathname.lastIndexOf('/')));
		}
	}, [history, pathname, state]);
	useEffect(() => {
		window.scrollTo(0, 0);

		const handleBrowserRedirect = () => {
			dispatch(setDetailedFilters({ filters: state.filters, query: state.query }));
		};
		window.addEventListener('popstate', handleBrowserRedirect);

		return () => {
			window.removeEventListener('popstate', handleBrowserRedirect);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (state && (state.fromTradeHistory || state.fromTrade || state.fromStatementTrade)) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<TradeHistoryDetails txId={state.txId} />
					</div>
				</div>
			</>
		);
	}

	if (
		state &&
		(state.fromDepositHistoryCrypto ||
			state.fromWithdrawalHistoryCrypto ||
			state.fromStatementDepositCrypto ||
			state.fromStatementWithdrawalCrypto)
	) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<DepositHistoryCryptoDetails txId={state.txId} />
						{/* <DetailedViewForm /> */}
					</div>
				</div>
			</>
		);
	}

	if (state && (state.fromDepositFiatHistory || state.fromStatementDepositFiat)) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<DepositHistoryFiatDetails txId={state.txId} />
						{/* <DetailedViewForm /> */}
					</div>
				</div>
			</>
		);
	}

	if (state && (state.fromWithdrawalFiatHistory || state.fromStatementWithdrawalFiat)) {
		return (
			<>
				<div className="wallet-operations-header-wrap">
					<div className="wallet-operations-header">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button
							type="button"
							className="btn btn--icon btn--back"
							onClick={() => history.goBack()}
						/>
					</div>
				</div>
				<div className="form form--type3">
					<div className="form-body">
						<WithdrawalHistoryFiatDetails txId={state.txId} />
						{/* <DetailedViewForm /> */}
					</div>
				</div>
			</>
		);
	}

	return <div>No Data</div>;
};

export default DetailedView;
