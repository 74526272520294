import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPostReferralsWalletTransfer, IReferralsStore, IReferralsWallet } from './types';
import { IReferralsWalletTransferResponse } from '../../../services/api/referrals/types';

export const initialState: IReferralsStore = {
	wallets: null,
	loading: false,
};

const referrals = createSlice({
	name: '@@referrals',
	initialState,
	reducers: {
		referralsInitialState: () => {
			return initialState;
		},
		referralsWalletsRequest: (state) => {
			const referralsState = state;
			referralsState.loading = true;
		},
		referralsWalletsSuccess: (state, action: PayloadAction<IReferralsWallet[] | null>) => {
			const referralsState = state;
			referralsState.loading = false;
			referralsState.wallets = action.payload;
		},
		referralsWalletTransferRequest: (
			state,
			action: PayloadAction<IPostReferralsWalletTransfer>,
		) => {
			const referralsState = state;
			referralsState.loading = true;
		},
		referralsWalletTransferSuccess: (
			state,
			action: PayloadAction<IReferralsWalletTransferResponse>,
		) => {
			const referralsState = state;
			referralsState.loading = false;
		},
		referralsWalletHideLoading: (state) => {
			const referralsState = state;
			referralsState.loading = false;
		},
	},
});

export default referrals.reducer;

export const {
	referralsInitialState,
	referralsWalletsRequest,
	referralsWalletsSuccess,
	referralsWalletTransferRequest,
	referralsWalletTransferSuccess,
	referralsWalletHideLoading,
} = referrals.actions;
