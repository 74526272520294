import Auth from '../../../layouts/Auth';
import Referrals from '../../../components/Referrals/Referrals';

const ReferralsPage = () => {
	return (
		<Auth title="referrals">
			<Referrals />
		</Auth>
	);
};

export default ReferralsPage;
