import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useWebSocket from 'react-use-websocket';

import Auth from 'layouts/Auth';
import {
	getWalletsBalance,
	getWalletsCryptoList,
	getWalletsFiatList,
	getWalletsIsLoad,
	getWalletsLayers,
} from 'redux/reducers/wallets/selectors';
import { getWalletsRequest, updateLayers } from 'redux/reducers/wallets/reducer';
import { currencyDataRequest } from 'redux/reducers/currency/reducer';
import { EWalletType, IWalletItem } from 'redux/reducers/wallets/types';
import { roundingNumber } from 'services/utils/roundingNumber';
import WalletsTable from 'components/Wallets/WalletsTable/WalletsTable';
import WalletSideBar from 'components/Wallets/WalletSideBar/WalletSideBar';
import { navList } from 'routes/routesList';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { motion } from 'framer-motion/dist/framer-motion';
import HeaderActions from 'layouts-elements/HeaderActions';
import { ILayersData, layersFilter } from 'services/utils/layersFilter';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';

const WalletsPage = () => {
	const dispatch = useDispatch();

	const [socketUrl, setSocketUrl] = useState(process.env.REACT_APP_SOCKET_URL as string);
	const { lastMessage } = useWebSocket(socketUrl);
	const [rate, setRate] = useState<number>(0);

	const layers = useSelector(getWalletsLayers);

	const loading = useSelector(getWalletsIsLoad);

	useEffect(() => {
		dispatch(getWalletsRequest());
		dispatch(currencyDataRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (lastMessage?.data) {
			dispatch(updateLayers(JSON.parse(lastMessage?.data)));
			const layerData: ILayersData | Record<string, any> = layersFilter('btc_eur', layers);

			const newRate = layerData?.data?.sell[1];

			if (newRate !== rate) {
				setRate(newRate);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lastMessage]);

	const walletsCryptoList = useSelector(getWalletsCryptoList);
	const walletsFiatList = useSelector(getWalletsFiatList);
	const walletsBalance = useSelector(getWalletsBalance);

	const [updatePriceAnim, setUpdatePriceAnim] = useState(false);
	useEffect(() => {
		setUpdatePriceAnim(true);
		const timeout = setTimeout(() => setUpdatePriceAnim(false), 10000);
		return () => {
			clearTimeout(timeout);
		};
	}, [loading]);

	const fiatBalance = useMemo(() => {
		return walletsFiatList?.reduce((acc: number, item: IWalletItem) => {
			const layerData: ILayersData | Record<string, any> = layersFilter(
				`${item.asset.code}_eur`,
				layers,
			);
			if (layerData?.data && item.balance) {
				return acc + Number(item?.balance) / Number(layerData?.data?.sell[1]);
			}
			return acc + item.balance_eur;
		}, 0);
	}, [walletsFiatList, layers]);

	const totalBalanceCrypto = useMemo(() => {
		const cryptoBalance = Number(walletsBalance?.crypto.btc) || 0;

		if (rate) return Number(cryptoBalance) + Number(fiatBalance) / rate;
		return 0;
	}, [walletsBalance, rate, fiatBalance]);

	const cryptoBalance = useMemo(() => {
		return toFixedNumber(Number(walletsBalance?.crypto.btc) * rate, 'EUR', true);
	}, [walletsBalance?.crypto.btc, rate]);

	return (
		<Auth>
			<section className="wallet-section">
				<div className="page-wrap">
					<WalletSideBar />
					<motion.div
						className="wallet-content"
						initial={{ opacity: 0 }}
						animate={{ opacity: 1, transition: { duration: 0.4 } }}
						exit={{ opacity: 0, transition: { duration: 0.4 } }}
					>
						<HeaderActions />
						<div className="wallet-content-header">
							<div className="wallet-content-header__title">
								<p>Wallets</p>
							</div>
						</div>
						<div className="wallet-content-balance flex">
							<div className="wallet-content-balance-item">
								<div className="wallet-content-balance-item__title">
									<p>Total Balance</p>
								</div>
								<div className="wallet-content-balance-item__value">
									<p className={`instant-trade-price ${updatePriceAnim ? 'updated' : ''}`}>
										{Number(
											(Number(walletsBalance?.crypto.btc) + Number(walletsBalance?.fiat.btc))
												.toFixed(6)
												.slice(0, -1),
										) > 0
											? toFixedNumber(totalBalanceCrypto * rate, '', true)
											: 0}
										<span>EUR</span>
									</p>
								</div>
								<div className="wallet-content-balance-item__user-currensy">
									{totalBalanceCrypto ? (
										<p>≈ {toFixedNumber(totalBalanceCrypto || 0, 'BTC', true)} BTC</p>
									) : (
										<p>≈ {toFixedNumber(0, 'BTC', true)} BTC</p>
									)}
								</div>
							</div>
							<div className="wallet-content-balance-item">
								<div className="wallet-content-balance-item__title">
									<p>Crypto Balance</p>
								</div>
								<div className="wallet-content-balance-item__value">
									<p className={`instant-trade-price ${updatePriceAnim ? 'updated' : ''}`}>
										{Number(walletsBalance?.crypto.btc.toFixed(6).slice(0, -1)) > 0
											? cryptoBalance
											: 0}
										<span>EUR</span>
									</p>
								</div>
								<div className="wallet-content-balance-item__user-currensy">
									<p>≈ {toFixedNumber(walletsBalance?.crypto.btc || 0, 'BTC', true)} BTC</p>
								</div>
							</div>
							<div className="wallet-content-balance-item">
								<div className="wallet-content-balance-item__title">
									<p>Fiat Balance</p>
								</div>
								<div className="wallet-content-balance-item__value">
									<p className={`instant-trade-price ${updatePriceAnim ? 'updated' : ''}`}>
										{Number(walletsBalance?.fiat.btc.toFixed(6).slice(0, -1)) > 0
											? toFixedNumber(fiatBalance, '', true)
											: 0}
										<span>EUR</span>
									</p>
								</div>
								<div className="wallet-content-balance-item__user-currensy">
									{!!fiatBalance && !!rate ? (
										<p>≈ {toFixedNumber(fiatBalance / rate, 'BTC', true)} BTC</p>
									) : (
										<p>≈ {toFixedNumber(0, 'BTC', true)} BTC</p>
									)}
								</div>
							</div>
						</div>
						<div className="wallet-content-tables">
							<WalletsTable
								title="Fiat Balances"
								walletsList={walletsFiatList}
								itemsPerPage={4}
								type={EWalletType.FIAT}
							/>

							<WalletsTable
								itemsPerPage={4}
								title="Crypto Balances"
								walletsList={walletsCryptoList}
								type={EWalletType.CRYPTO}
							/>
						</div>
					</motion.div>
				</div>
			</section>
		</Auth>
	);
};

export default WalletsPage;
