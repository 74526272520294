import { FC } from 'react';
import moment from 'moment/moment';

import { IUser } from 'redux/reducers/auth/types';
import { IApiCryptoHistoryItem, IApiFiatHistoryItem } from 'services/api/transactions/types';
import {
	IDetailedViewCryptoDetails,
	IDetailedViewTradeDetails,
} from 'redux/reducers/detailedView/types';

interface IDefaultField {
	item: IDetailedViewTradeDetails | IDetailedViewCryptoDetails;
	userData: IUser;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const DefaultFields: FC<IDefaultField> = ({ item, userData }) => {
	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};
	const [date, time] = formatDateTime(item.updated_at);

	return (
		<>
			<CustomInput
				label="Timestamp"
				value={`${moment.utc(item.updated_at).format('YYYY-MM-DD')} ${moment
					.utc(item.updated_at)
					.format('HH:mm:ss')}`}
				type="text"
				disabled
			/>
			<CustomInput
				label="User Email"
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				value={`${userData?.email}`}
				type="text"
				disabled
			/>

			<CustomInput
				label="User Full Name"
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				value={`${userData?.data.first_name} ${userData?.data.last_name}`}
				type="text"
				disabled
			/>
			{userData?.type === 'corporate' && (
				<CustomInput
					label="Company Name"
					// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
					value={`${userData?.data?.company_name}`}
					type="text"
					disabled
				/>
			)}
		</>
	);
};

export default DefaultFields;
