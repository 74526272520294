import React, { FC, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IPopUp } from '../types';
import { getOndatoLinkLoading } from '../../../redux/reducers/settings/selectors';
import { getOndatoLinkRequest } from '../../../redux/reducers/settings/reducer';
import { IGetOndatoLinkWorker } from '../../../redux/reducers/settings/types';
import { GetOndatoLinkResponse } from '../../../services/api/settings/types';

const OndatoPopUp: FC<IPopUp> = ({ open, closeModal }) => {
	const isLoading = useSelector(getOndatoLinkLoading);
	const history = useHistory();
	const dispatch = useDispatch();
	const [ondatoUrl, setOndatoUrl] = useState('');

	useEffect(() => {
		const params: IGetOndatoLinkWorker = {
			onFinally(data: GetOndatoLinkResponse | null) {
				if (data) {
					setOndatoUrl(data?.url);
				}
			},
		};
		dispatch(getOndatoLinkRequest(params));
	}, [dispatch]);

	return (
		<>
			<Popup
				open={open}
				closeOnDocumentClick
				onClose={() => {
					closeModal();
					history.push('/');
				}}
			>
				<div className="modal">
					<div className="popup popup--width-660">
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<button type="button" className="popup__close-btn" onClick={closeModal} />
						<div className="popup-header popup-header--mb-20">
							<p className="popup-header__title">Identity verification</p>
						</div>
						<div className="popup-icon popup-icon--type2">
							{isLoading ? (
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="80"
									height="80"
									viewBox="0 0 80 80"
									fill="none"
								>
									<rect x="0.5" y="0.5" width="79" height="79" rx="39.5" stroke="#EE9E53" />
									<path
										d="M40 65C53.75 65 65 53.75 65 40C65 26.25 53.75 15 40 15C26.25 15 15 26.25 15 40C15 53.75 26.25 65 40 65Z"
										fill="#EE9E53"
									/>
									<path
										d="M49.9993 40C49.9993 34.4772 45.5221 30 39.9993 30C35.7078 30 32.0476 32.7033 30.6289 36.5"
										stroke="white"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M30.4531 40.8665C30.4531 46.3893 34.9303 50.8665 40.4531 50.8665C44.4499 50.8665 47.899 48.5217 49.5 45.1327"
										stroke="white"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M46.0263 37.7088L50.2025 40.2648L52.8036 35.7832"
										stroke="white"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<path
										d="M34.4268 43.1576L30.2506 40.6016L27.6495 45.0833"
										stroke="white"
										strokeWidth="2"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
								</svg>
							) : (
								<svg
									width="80"
									height="80"
									viewBox="0 0 80 80"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<rect x="0.5" y="0.5" width="79" height="79" rx="39.5" stroke="#5EC45C" />
									<rect x="20.5" y="20" width="40" height="40" rx="20" fill="#5EC45C" />
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M40.5008 30.7C35.3645 30.7 31.2008 34.8638 31.2008 40C31.2008 45.1363 35.3645 49.3 40.5008 49.3C45.637 49.3 49.8008 45.1363 49.8008 40C49.8008 34.8638 45.637 30.7 40.5008 30.7ZM29.8008 40C29.8008 34.0906 34.5913 29.3 40.5008 29.3C46.4102 29.3 51.2008 34.0906 51.2008 40C51.2008 45.9095 46.4102 50.7001 40.5008 50.7001C34.5913 50.7001 29.8008 45.9095 29.8008 40Z"
										fill="white"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M45.9345 35.4507C46.238 35.6902 46.2898 36.1304 46.0502 36.4338L39.7344 44.4338C39.6097 44.5918 39.4231 44.6884 39.2222 44.6991C39.0212 44.7098 38.8254 44.6335 38.6847 44.4897L35.0005 40.725C34.7301 40.4487 34.7349 40.0055 35.0112 39.7351C35.2875 39.4647 35.7307 39.4695 36.0011 39.7458L39.1287 42.9417L44.9514 35.5663C45.1909 35.2629 45.6311 35.2111 45.9345 35.4507Z"
										fill="white"
									/>
								</svg>
							)}
						</div>
						<div className="popup-body">
							<div className="popup-text popup-text--type2">
								<p>
									Your documents have been uploaded successfully. In order to complete the
									verification process, please proceed by clicking the “Start verification” button.
									<br />
									<br />
									Kindly ensure you have your ID or Passport ready
								</p>
							</div>
						</div>
						<div className="popup-footer mt-32">
							<div className="popup-submit">
								<button
									type="button"
									className="btn btn-primary"
									disabled={isLoading}
									onClick={() => {
										window.location.href = ondatoUrl;
									}}
								>
									Start verification
								</button>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
};

export default OndatoPopUp;
