/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ITradeExchangeRatePayload } from 'services/api/transactions/types';
import { toDinamicDecimals, toMaxDecimals } from 'services/utils/numbers';
import { ITradeChangeAssetCountPayload, ITradeChangeAssetPayload, ITradeStore } from './types';

// ==========================================:
export const initialState: ITradeStore = {
	from_asset_id: null,
	from_asset_code: null,
	from_asset_count: null,
	from_asset_count_raw: null,
	to_asset_id: null,
	to_asset_code: null,
	to_asset_count: null,
	to_asset_count_raw: null,
	main_price: null,
	trade_fee: null,
	reversed: false,
	layers: null,
	layersLog: null,
	pair_code_raw: '',
	exchange_rate_raw: null,
	last_input: 'You Sell',
};
// ==========================================:
const trade = createSlice({
	name: '@@trade',
	initialState,
	reducers: {
		initialTradeValues: (state, { payload }: PayloadAction<ITradeStore>) => {
			const newState = state;
			newState.from_asset_id = payload.from_asset_id;
			newState.from_asset_code = payload.from_asset_code;
			newState.from_asset_count = payload.from_asset_count;
			newState.to_asset_id = payload.to_asset_id;
			newState.to_asset_code = payload.to_asset_code;
			newState.to_asset_count = payload.to_asset_count;
		},
		swapTradeValues: (state) => {
			const newState = state;
			const stateCopy = { ...state };
			newState.from_asset_id = stateCopy.to_asset_id;
			newState.from_asset_code = stateCopy.to_asset_code;
			newState.from_asset_count = stateCopy.to_asset_count;
			newState.to_asset_id = stateCopy.from_asset_id;
			newState.to_asset_code = stateCopy.from_asset_code;
			newState.to_asset_count = stateCopy.from_asset_count;
		},
		changeAsset: (state, { payload }: PayloadAction<ITradeChangeAssetPayload>) => {
			const newState = state;
			if (payload.type === 'from') {
				newState.from_asset_id = payload.asset_id || '';
				newState.from_asset_code = payload.asset_code || '';
			} else if (payload.type === 'to') {
				newState.to_asset_id = payload.asset_id || '';
				newState.to_asset_code = payload.asset_code || '';
			}
			newState.from_asset_count = '';
			newState.to_asset_count = '';
		},

		changeAssetCount: (state, { payload }: PayloadAction<ITradeChangeAssetCountPayload>) => {
			const newState = state;
			if (payload.type === 'from') {
				newState.from_asset_count = payload.asset_count;
			} else if (payload.type === 'to') {
				newState.to_asset_count = payload.asset_count;
			}
		},

		changeMainPrice: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.main_price = payload;
		},

		updateTradeFee: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.trade_fee = payload;
		},

		updateReversed: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.reversed = payload;
		},

		updateLayers: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.layers = payload;
		},

		updateLayersLog: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.layersLog = payload;
		},

		updateTradePairRaw: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.pair_code_raw = payload;
		},

		updateExchangeRateRaw: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.exchange_rate_raw = payload;
		},

		updateFromAssetCountRaw: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.from_asset_count_raw = payload;
		},

		updateToAssetCountRaw: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			newState.to_asset_count_raw = payload;
		},

		updateLastInput: (state, { payload }: PayloadAction<string>) => {
			const newState = state;
			newState.last_input = payload;
		},

		tradeInitState: () => initialState,
	},
});

export default trade.reducer;
export const {
	initialTradeValues,
	swapTradeValues,
	changeAsset,
	changeAssetCount,
	tradeInitState,
	updateLayers,
	updateLayersLog,
	changeMainPrice,
	updateTradeFee,
	updateReversed,
	updateTradePairRaw,
	updateExchangeRateRaw,
	updateFromAssetCountRaw,
	updateToAssetCountRaw,
	updateLastInput,
} = trade.actions;
