import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { getDetailedViewLoading, getFiatDetails } from 'redux/reducers/detailedView/selectors';
import { getFiatDetailsRequest } from 'redux/reducers/detailedView/reducer';
import Loading from 'layouts-elements/Loading/Loading';

import { roundingNumber } from '../../../services/utils/roundingNumber';
import { toFixedNumber } from '../../../services/utils/toFixedNumber';
import { statusNames } from '../../TransactionHistory/utils';
import DetailedViewWithdrawalFiatForm from '../DetailedViewForm/DetailedViewWithdrawalFiatForm';

export interface IDepositHistoryFiatDetails {
	txId: string | number;
}

interface ICustomInput {
	label: string;
	value: string | number | undefined;
	type: string;
	disabled: boolean;
}

const CustomInput: FC<ICustomInput> = ({ label, value, type, disabled }) => {
	return (
		<div className="input">
			<label>
				<p className="input__name">{label}</p>
				<div className="input-wrapper">
					<input type={type} className="input-item" value={value} disabled={disabled} />
				</div>
			</label>
		</div>
	);
};

const WithdrawalHistoryFiatDetails: FC<IDepositHistoryFiatDetails> = ({ txId }) => {
	const dispatch = useDispatch();
	const item = useSelector(getFiatDetails);
	const loading = useSelector(getDetailedViewLoading);

	useEffect(() => {
		if (txId) {
			dispatch(getFiatDetailsRequest(Number(txId)));
		}
	}, [dispatch, txId]);

	const formatDateTime = (sourceDate: string) => {
		const date = new Date(sourceDate);

		const yyyy = date.getFullYear();
		const mo = String(date.getMonth() + 1).padStart(2, '0');
		const dd = String(date.getDate()).padStart(2, '0');
		const hh = String(date.getHours()).padStart(2, '0');
		const mm = String(date.getMinutes()).padStart(2, '0');
		const ss = String(date.getSeconds()).padStart(2, '0');

		return [`${yyyy}-${mo}-${dd}`, `${hh}:${mm}:${ss}`];
	};

	if (item && !loading) {
		const [date, time] = formatDateTime(item.created_at);
		return (
			<div className="detailed-view">
				<div className="input-wrap--col-2">
					{item.type === 'deposit' ? (
						<>
							<CustomInput
								label="Timestamp"
								value={`${moment.utc(item.created_at).format('YYYY-MM-DD')} ${moment
									.utc(item.created_at)
									.format('HH:mm:ss')}`}
								type="text"
								disabled
							/>
						</>
					) : (
						<>
							<CustomInput label="Timestamp" value={`${date} ${time}`} type="text" disabled />
						</>
					)}
					<CustomInput
						label="User Email"
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						value={`${item.user.email}`}
						type="text"
						disabled
					/>
					<CustomInput
						label="User Full Name"
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						value={`${item.user.data.first_name} ${item.user?.data.last_name}`}
						type="text"
						disabled
					/>
					{item.user?.type === 'corporate' && (
						<CustomInput
							label="Company Name"
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
							value={`${item.user?.data?.company_name}`}
							type="text"
							disabled
						/>
					)}
					<CustomInput
						label="Currency"
						// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						value={`${item.asset.code.toUpperCase()} ${item.asset.name}`}
						type="text"
						disabled
					/>
					<CustomInput
						label="Gross"
						value={`${roundingNumber(
							item.gross_fee,
							item.asset.code,
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						)} ${item.asset.code.toUpperCase()}`}
						type="text"
						disabled
					/>
					<CustomInput
						label="Fee"
						value={`${roundingNumber(
							item.fee,
							item.asset.code,
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						)} ${item.asset.code.toUpperCase()}`}
						type="text"
						disabled
					/>
					<CustomInput
						label="Net"
						value={`${toFixedNumber(
							item.net_fee,
							item.asset.code,
							true,
							// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
						)} ${item.asset.code.toUpperCase()}`}
						type="text"
						disabled
					/>
					{item?.bank_account?.account_number && (
						<>
							<CustomInput
								label="Destination"
								// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
								value={`${item?.bank_account?.bank_name ? item?.bank_account?.bank_name : '----'} ${
									// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
									item?.bank_account?.account_number
								}`}
								type="text"
								disabled
							/>
						</>
					)}
					{/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
					<CustomInput label="Tx ID" value={`${item.id}`} type="text" disabled />
					{item.user?.type !== 'corporate' && (
						<CustomInput
							label="Status"
							value={`${statusNames[item.status]}`}
							type="text"
							disabled
						/>
					)}
				</div>
				{item.user?.type === 'corporate' && (
					<CustomInput label="Status" value={`${statusNames[item.status]}`} type="text" disabled />
				)}
				<DetailedViewWithdrawalFiatForm formData={item} />
			</div>
		);
	}
	if (!item && !loading) {
		return <div>No data.</div>;
	}

	return <Loading />;
};

export default WithdrawalHistoryFiatDetails;
